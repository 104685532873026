import React, { Fragment, useState } from "react"
import { motion } from 'framer-motion';

import ExampleImage from '../../assets/images/contactexample.svg';

import './styles.scss';

const Contact = () => {
  const [fieldsActive, setFieldsActive] = useState({
    name: false,
    email: false,
    company: false,
    about: false
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    about: '',
    web_apps: false,
    mobile_apps: false,
    ui_ux: false,
    user_research: false
  });

  const handleChange = e => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    setFormData({ ...formData, [inputName]: inputValue });

    if (inputValue !== '') {
      setFieldsActive({ ...fieldsActive, [inputName]: true});
    } else {
      setFieldsActive({ ...fieldsActive, [inputName]: false});
    }
};

  const handleToggle = e => setFormData({ ...formData, [e.target.name]: !formData[e.target.name] });

  const formEncodeString = (str) => encodeURIComponent(str).replace(/%20/g, '+');

  const encodeData = (obj) => (
    Object.entries(obj)
      .map(pair =>
        formEncodeString(pair[0])
        + '='
        + formEncodeString(pair[1])
      )
      .join('&')
  );

  const handleSubmit = async e => {
    e.preventDefault();
    return (
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeData({
          ...formData,
          "form-name": "Contact Form"
        })
      })
      .then(function (data) {
        setFormSubmitted(true);
      })
      .catch(error => {
        console.error('Error: ', error);
      })
    );
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        staggerDirection: 1
      }
    }
  }

  const itemVariants = {
    hidden: { y: 100, opacity: 0 },
    show: { y: 0, opacity: 1 }
  }

  return (
    <div className="contact">
      <div className="container">
        <div className="contact__inner">
          <div className="contact__content">
            { !formSubmitted && (
              <Fragment>
                <h3 className="contact__title">How can we help?</h3>
                <form
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  name="Contact Form"
                  method="post"
                  onSubmit={ handleSubmit }
                  className="contact__form"
                >
                  <input type="hidden" name="form-name" value="Contact Form" />
                  <input type="hidden" name="bot-field" />
                  <fieldset>
                    <div className="contact__checkbox">
                      <input
                        type="checkbox"
                        name="mobile_apps"
                        id="mobile_apps"
                        onChange={ handleToggle }
                        checked={ formData.mobile_apps }
                      />
                      <label htmlFor="mobile_apps">Mobile App</label>
                    </div>
                    <div className="contact__checkbox">
                      <input
                        type="checkbox"
                        name="web_apps"
                        id="web_apps"
                        onChange={ handleToggle }
                        checked={ formData.web_apps }
                      />
                      <label htmlFor="web_apps">Web App</label>
                    </div>
                    <div className="contact__checkbox">
                      <input
                        type="checkbox"
                        name="ui_ux"
                        id="ui_ux"
                        onChange={ handleToggle }
                        checked={ formData.ui_ux }
                      />
                      <label htmlFor="ui_ux">UI/UX Design</label>
                    </div>
                    <div className="contact__checkbox">
                      <input
                        type="checkbox"
                        name="user_research"
                        id="user_research"
                        onChange={ handleToggle }
                        checked={ formData.user_research }
                      />
                      <label htmlFor="user_research">Usability Research</label>
                    </div>
                  </fieldset>
                  <div className="contact__input">
                    <input type="text" name="name" onChange={ handleChange } value={ formData.name } required />
                    <label className={ fieldsActive.name ? "active" : "" } htmlFor="name">
                      Name
                    </label>
                  </div>
                  <div className="contact__input">
                    <input type="email" name="email" onChange={ handleChange } value={ formData.email } required />
                    <label className={ fieldsActive.email ? "active" : "" } htmlFor="email">
                      Email
                    </label>
                  </div>
                  <div className="contact__input">
                    <input type="text" name="company" onChange={ handleChange } value={ formData.company } />
                    <label className={ fieldsActive.company ? "active" : "" } htmlFor="company">
                      Company name (Optional)
                    </label>
                  </div>
                  <div className="contact__input">
                    <input type="text" name="about" onChange={ handleChange } value={ formData.about } required />
                    <label className={ fieldsActive.about ? "active" : "" } htmlFor="about">
                      Tell us about your project
                    </label>
                  </div>
                  <input type="submit" value="Submit"/>
                </form>
              </Fragment>
            )}
            { formSubmitted && (
              <motion.div
                className="contact__success"
                variants={ containerVariants }
                initial="hidden"
                animate="show"
              >
                <motion.h3 variants={ itemVariants }>Thanks for getting in touch!</motion.h3>
                <motion.h4 variants={ itemVariants }>We will get back to you as soon as we can.</motion.h4>
              </motion.div>
            )}
          </div>
          <div className="contact__img">
            <img src={ ExampleImage } alt="Example"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
