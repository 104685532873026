import React from 'react';

import FaqsContent from './faqs-content/index';
import FaqShapes from './faqs-shapes/index';

import './styles.scss';

const Faqs = () => (
  <div className="faqs-container">
    <FaqsContent />
    <FaqShapes />
  </div>
);

export default Faqs;
