import React from "react"
import { motion } from "framer-motion";
import { Link } from 'react-scroll'

import classList from '../../helpers/class_list';

import Logo from '../../assets/images/logo.svg';

import './styles.scss';

const Navbar = ({ activeSection }) => {
  const navbarVariants = {
    hidden: { opacity: 0, y: -200 },
    visible: { opacity: 1, y: 0 }
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={ navbarVariants }
      transition={{ duration: 0.3 }}
      className="navbar"
    >
      <div className="navbar__container">
        <div className="navbar__logo">
          <Link
            activeClass="active"
            to="hero"
            spy={true}
            smooth={true}
            duration={500}
          >
            <img src={ Logo } alt="Peera"/>
          </Link>
        </div>
        <ul className="navbar__links">
          <li className={ classList("navbar__link", { active: activeSection === 1})}>
            <Link
              activeClass="active"
              to="how-we-work"
              spy={true}
              smooth={true}
              duration={500}
              offset={ -81 }
            >
              How we work
            </Link>
          </li>
          <li className={ classList("navbar__link", { active: activeSection === 2})}>
            <Link
              activeClass="active"
              to="what-we-do"
              spy={true}
              smooth={true}
              duration={500}
              offset={ -81 }
            >
              What we do
            </Link>
          </li>
          <li className={ classList("navbar__link", { active: activeSection === 3})}>
            <Link
              activeClass="active"
              to="why-choose-us"
              spy={true}
              smooth={true}
              duration={500}
              offset={ -81 }
            >
              Why choose us
            </Link>
          </li>
          <li className={ classList("navbar__link", { active: activeSection === 4})}>
            <Link
              activeClass="active"
              to="faqs"
              spy={true}
              smooth={true}
              duration={500}
              offset={ -81 }
            >
              Faqs
            </Link>
          </li>
        </ul>
      </div>
    </motion.div>
  );
};

export default Navbar;
