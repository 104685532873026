import React, { memo, useState }  from "react"
import { graphql, StaticQuery } from "gatsby";

import WhyChooseUsImg from './why-choose-us-img/index';
import WhyChooseUsContent from './why-choose-us-content/index';

import './styles.scss';

const WhyChooseUs = memo(({ data }) => {
  const sections = data.allWhyChooseUsJson.nodes;
  const tabs = sections.map(section => section.tab);
  const [currentSection, setCurrentSection] = useState(0);
  const { img, img_alt, title, body, quote, author } = sections[currentSection];

  return (
    <div className="why-choose-us">
      <div className="container">
        <div className="why-choose-us__inner">
          <WhyChooseUsImg imgUrl={ img } imgAltText={ img_alt } key={ `why-choose-us-img-${currentSection}` } />
          <div className="why-choose-us__section">
            <ul className="why-choose-us__tabs">
              <div className="why-choose-us__tabs-inner">
                { tabs.map((tab, i) => (
                  <li
                    className={`why-choose-us__tab ${i === currentSection ? 'active' : null }`}
                    key={`tab-${i}`}
                    onClick={ () => setCurrentSection(i) }
                    onKeyPress={ () => setCurrentSection(i) }
                    role="button"
                    tabIndex={ 0 }
                  >
                    { tab }
                  </li>
                ))}
              </div>
            </ul>
            <WhyChooseUsContent
              body={ body }
              title={ title }
              quote={ quote }
              author={ author }
              key={ `why-choose-us-content-${currentSection}` }
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default () => (
  <StaticQuery
    query={graphql`
      query WhyChooseUsQuery {
        allWhyChooseUsJson {
          nodes {
            img
            img_alt
            tab
            title
            body
            quote
            author
          }
        }
      }
    `}
    render={(data, count) => <WhyChooseUs data={data} />}
  />
);
