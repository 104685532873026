import React from "react"
import { motion } from "framer-motion";

const WhyChooseUsImg = ({ imgUrl, imgAltText }) => (
  <motion.div
    className="why-choose-us__img"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
  >
    <motion.img
      src={ imgUrl }
      alt={ imgAltText }
      initial={{ x: -200 }}
      animate={{ x: 0 }}
      loading="lazy"
    />
  </motion.div>
);

export default WhyChooseUsImg;
