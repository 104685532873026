import React, { memo } from "react"
import { graphql, StaticQuery } from "gatsby";
import Slider from 'react-slick';
import { motion } from "framer-motion";

import WhatWeDoSlide from '../what-we-do-slide/index';

import EllipsePurple from '../../assets/images/whatwedoellipsepurple.svg';
import EllipseOrange from '../../assets/images/whatwedoellipseorange.svg';
import CircleOrange from '../../assets/images/circle-orange.svg';
import CircleGreen from '../../assets/images/circle-green.svg';
import SquarePurple from '../../assets/images/square-purple.png';
import TriangleGreen from '../../assets/images/triangle-green.svg';
import ChevronLeft from '../../assets/images/chevron-white-left.svg';
import ChevronRight from '../../assets/images/chevron-white-right.svg';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.scss';

const NextArrow = ({ onClick }) => (
  <button className="slider-button slider-button--next" onClick={ onClick }>
    <img src={ ChevronRight } alt=""/>
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button className="slider-button slider-button--prev" onClick={ onClick }>
    <img src={ ChevronLeft} alt=""/>
  </button>
);

const WhatWeDo = memo(({ data }) => {
  const slides = data.allWhatWeDoJson.nodes;
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 660,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: function() {
      return (
        <div className="slider-dot" />
      )
    }
  }

  return (
    <div className="what-we-do">
      <div className="container">
        <div className="what-we-do__slider">
          <Slider {...settings}>
            { slides.map((slide, i) => (
              <WhatWeDoSlide
                body={ slide.body }
                colour={ slide.colour }
                img={ slide.img }
                img_alt={ slide.img_alt }
                key={ `what-we-do-${i}` }
                logos={ slide.logos }
                title={ slide.title }
              />
            ))}
          </Slider>
        </div>
        <motion.img
          src={ EllipsePurple }
          className="what-we-do__shape what-we-do__shape--ellipse-purple"
          alt="Ellipse Purple"
          animate={{
            rotate: [0, 45, 0],
            y: [0, -20, 20, 0]
          }}
          transition={{ repeat: Infinity, duration: 15 }}
        />
        <motion.img
          src={ EllipseOrange }
          className="what-we-do__shape what-we-do__shape--ellipse-orange"
          alt="Ellipse Orange"
          animate={{
            rotate: [0, 45, 0],
            y: [0, 30, -30, 0]
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        />
        <div className="what-we-do__shapes what-we-do__shapes--left">
          <motion.img
            src={ CircleOrange }
            className="what-we-do__shape what-we-do__shape--circle-orange"
            alt="Circle Orange"
            animate={{
              y: [0, 5, -5, 0],
              x: [0, -10, 10, 0]
            }}
            transition={{ repeat: Infinity, duration: 20 }}
          />
          <motion.img
            src={ CircleGreen }
            className="what-we-do__shape what-we-do__shape--circle-green"
            alt="Circle Green"
            animate={{
              x: [0, 5, -5, 0],
              y: [0, 10, -5, 0]
            }}
            transition={{ repeat: Infinity, duration: 12 }}
          />
          <motion.img
            src={ SquarePurple }
            className="what-we-do__shape what-we-do__shape--square-purple"
            alt="Square Purple"
            animate={{
              y: [0, -10, 5, 0],
              rotate: [0, -270, 0]
            }}
            transition={{ repeat: Infinity, duration: 15 }}
          />
          <motion.img
            src={ TriangleGreen }
            className="what-we-do__shape what-we-do__shape--triangle-green"
            alt="Triangle Green"
            animate={{
              y: [0, 5, -5, 0],
              rotate: [0, 45, 0]
            }}
            transition={{ repeat: Infinity, duration: 20 }}
          />
        </div>
        <div className="what-we-do__shapes what-we-do__shapes--right">
          <motion.img
            src={ CircleOrange }
            className="what-we-do__shape what-we-do__shape--circle-orange"
            alt="Circle Orange"
            animate={{
              x: [0, 5, -5, 0]
            }}
            transition={{ repeat: Infinity, duration: 15 }}
          />
          <motion.img
            src={ CircleGreen }
            className="what-we-do__shape what-we-do__shape--circle-green"
            alt="Circle Green"
            animate={{
              x: [0, 5, -5, 0]
            }}
            transition={{ repeat: Infinity, duration: 10 }}
          />
          <motion.img
            src={ SquarePurple }
            className="what-we-do__shape what-we-do__shape--square-purple"
            alt="Square Purple"
            animate={{
              y: [0, -5, 5, 0],
              rotate: [0, 180, 0]
            }}
            transition={{ repeat: Infinity, duration: 15 }}
          />
        </div>
      </div>
    </div>
  );
});

export default () => (
  <StaticQuery
    query={graphql`
      query WhatWeDoQuery {
        allWhatWeDoJson {
          nodes {
            body
            colour
            img
            img_alt
            logos {
              img
              title
            }
            title
          }
        }
      }
    `}
    render={(data, count) => <WhatWeDo data={data} />}
  />
)
