import React from "react"
import { motion } from "framer-motion";

import CircleGreen from '../../../assets/images/circle-green.svg';
import CirclePurple from '../../../assets/images/circle-purple.svg';
import SquarePurple from '../../../assets/images/square-purple.png';
import SquareOrange from '../../../assets/images/square-orange.svg';

const WhyChooseUsContent= ({
  body,
  title,
  author,
  quote
}) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        staggerDirection: 1
      }
    }
  }

  const itemVariants = {
    hidden: { y: 100, opacity: 0 },
    show: { y: 0, opacity: 1 }
  }

  const shapesLeftVariants = {
    hidden: { opacity: 0, x: -20, y: 20 },
    visible: { opacity: 1, x: 0, y: 0 },
  };

  const shapesRightVariants = {
    hidden: { opacity: 0, x: 20, y: -20 },
    visible: { opacity: 1, x: 0, y: 0 },
  };

  return (
    <motion.div
      className="why-choose-us__content"
      variants={ containerVariants }
      initial="hidden"
      animate="show"
    >
      <motion.h4
        variants={ itemVariants }
      >
        { title }
      </motion.h4>
      <motion.div
        className="why-choose-us__body"
        dangerouslySetInnerHTML={{ __html: body }}
        variants={ itemVariants }
      />
      {/* <motion.blockquote variants={ itemVariants }>
        { quote }
        <motion.footer variants={ itemVariants }>
          { author }
        </motion.footer>
      </motion.blockquote> */}
      <motion.div
        className="why-choose-us__shapes why-choose-us__shapes--left"
        initial="hidden"
        animate="visible"
        variants={ shapesLeftVariants }
        transition={{ duration: 0.6 }}
      >
        <motion.img
          src={ CirclePurple}
          className="why-choose-us__shape why-choose-us__shape--circle-purple"
          alt="Circle Purple"
          animate={{
            y: [0, 5, -5, 0],
            x: [0, -10, 10, 0]
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        />
        <motion.img
          src={ SquareOrange }
          className="why-choose-us__shape why-choose-us__shape--square-orange"
          alt="Square Orange"
          animate={{
            x: [0, 5, -5, 0],
            y: [0, 10, -5, 0],
            rotate: [0, 180, 0]
          }}
          transition={{ repeat: Infinity, duration: 12 }}
        />
      </motion.div>
      <motion.div
        className="why-choose-us__shapes why-choose-us__shapes--right"
        initial="hidden"
        animate="visible"
        variants={ shapesRightVariants }
        transition={{ duration: 0.6 }}
      >
        <motion.img
          src={ CircleGreen }
          className="why-choose-us__shape why-choose-us__shape--circle-green"
          alt="Circle Green"
          animate={{
            y: [0, 5, -5, 0],
            x: [0, -10, 10, 0]
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        />
        <motion.img
          src={ SquarePurple }
          className="why-choose-us__shape why-choose-us__shape--square-purple"
          alt="Square Purple"
          animate={{
            x: [0, 5, -5, 0],
            y: [0, 10, -5, 0],
            rotate: [0, -270, 0]
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        />
      </motion.div>
    </motion.div>
  );
};

export default WhyChooseUsContent;