import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby";

import Accordion from '../../accordion/index';

import ExampleImage from '../../../assets/images/faqsexample.svg';

const FaqsContent = ({ data }) => {
  const faqs = data.allFaqsJson.nodes;
  const [expanded, setExpanded] = useState(0);

  return (
    <div className="faqs">
      <div className="container">
        <div className="faqs__inner">
          <div className="faqs__img">
            <img src={ ExampleImage } alt="Example"/>
          </div>
          <div className="faqs__content">
            <h2 className="faqs__title">FAQs</h2>
            <div className="faqs__faqs">
              { faqs.map((faq, i) => (
                <Accordion
                  body={ faq.body }
                  heading={ faq.heading }
                  i={i}
                  expanded={ expanded }
                  setExpanded={ setExpanded }
                  key={i}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query FaqsQuery {
        allFaqsJson {
          nodes {
            body
            heading
          }
        }
      }
    `}
    render={(data, count) => <FaqsContent data={data} />}
  />
);
