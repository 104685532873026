import React, { useState, useEffect } from 'react';
import Typist from "react-typist";

const HeroText = () => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    setCount(1);
  }, [count]);

  return (
    <h1 className="hero__title">
      We
      { count ? (
        <Typist avgTypingDelay={150} onTypingDone={() => setCount(0)}>
          <span>design</span>
          <Typist.Backspace count={6} delay={1500} />
          <span>develop</span>
          <Typist.Backspace count={7} delay={1500} />
          <span>test</span>
          <Typist.Backspace count={4} delay={1500} />
          <span>launch</span>
          <Typist.Backspace count={6} delay={1500} />
          <span>maintain</span>
          <Typist.Backspace count={8} delay={1500} />
        </Typist>
      ) : (
        ""
      )}
      <span className="hero__mobile-text">apps <span className="hero__desktop-text">for Founders</span></span>
    </h1>
  );
}

export default HeroText;
