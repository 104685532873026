import React from "react"

import classList from '../../helpers/class_list';

import './styles.scss';

const WhatWeDoSlide = ({
  body,
  colour,
  img,
  img_alt,
  logos,
  title
}) => (
  <div className={ classList('what-we-do-slide', { [colour]: colour })}>
    <div className="what-we-do-slide__inner">
      <div className="what-we-do-slide__img what-we-do-slide__img--bespoke">
        <img src={ img } alt={ img_alt } />
      </div>
      <div className="what-we-do-slide__content">
        <div className="what-we-do-slide__title">
          <h4>{ title }</h4>
        </div>
        <div className="what-we-do-slide__body" dangerouslySetInnerHTML={{ __html: body }} />
        <div className="what-we-do-slide__logos">
          { logos.map(logo => (
            <div className="what-we-do-slide__logo">
              <img src={ logo.img } alt={ logo.title } key={ `${logo.title}-logo` } />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default WhatWeDoSlide;
