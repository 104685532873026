import React from 'react';
import { motion } from "framer-motion";

import EllipseOrange from '../../../assets/images/faqs-ellipse-orange.svg';
import EllipsePurple from '../../../assets/images/faqs-ellipse-purple.svg';
import CircleOrange from '../../../assets/images/circle-orange.svg';
import CircleGreen from '../../../assets/images/circle-green.svg';
import SquarePurple from '../../../assets/images/square-purple.png';
import TriangleOrange from '../../../assets/images/triangle-orange.svg';

const FaqShapes = () => (
  <div className="faq-shapes">
    <div className="container">
      <motion.img
        src={ EllipseOrange }
        className="faqs__shape faqs__shape--ellipse-orange"
        alt="faq-ellipse-orange"
        animate={{
          rotate: [0, 45, 0],
          y: [0, -20, 20, 0]
        }}
        transition={{ repeat: Infinity, duration: 15 }}
      />
      <motion.img
        src={ EllipsePurple }
        className="faqs__shape faqs__shape--ellipse-purple"
        alt="faq-ellipse-purple"
        animate={{
          rotate: [0, 45, 0],
          y: [0, 30, -30, 0]
        }}
        transition={{ repeat: Infinity, duration: 20 }}
      />
      <div className="faqs__shapes faqs__shapes--left">
        <motion.img
          src={ CircleOrange }
          className="faqs__shape faqs__shape--circle-orange"
          animate={{
            y: [0, 5, -5, 0],
            x: [0, -10, 10, 0],
            rotate: [0, 0.05, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        />
        <motion.img
          src={ SquarePurple }
          className="faqs__shape faqs__shape--square-purple"
          animate={{
            y: [0, 5, -5, 0],
            rotate: [0, 360, 0]
          }}
          transition={{ repeat: Infinity, duration: 10 }}
        />
        <motion.img
          src={ CircleGreen }
          className="faqs__shape faqs__shape--circle-green"
          animate={{
            x: [0, 5, -5, 0],
            rotate: [0, 0.05, 0],
            y: [0, 10, -5, 0]
          }}
          transition={{ repeat: Infinity, duration: 12 }}
        />
      </div>
      <div className="faqs__shapes faqs__shapes--right">
        <motion.img
          src={ CircleOrange }
          className="faqs__shape faqs__shape--circle-orange"
          animate={{
            x: [0, 5, -5, 0],
            rotate: [0, 0.05, 0],
          }}
          transition={{ repeat: Infinity, duration: 15 }}
        />
        <motion.img
          src={ SquarePurple }
          className="faqs__shape faqs__shape--square-purple"
          animate={{
            y: [0, -5, 5, 0],
            rotate: [0, 180, 0]
          }}
          transition={{ repeat: Infinity, duration: 15 }}
        />
        <motion.img
          src={ CircleGreen }
          className="faqs__shape faqs__shape--circle-green"
          animate={{
            x: [0, 5, -5, 0],
            rotate: [0, 0.05, 0],
          }}
          transition={{ repeat: Infinity, duration: 10 }}
        />
        <motion.img
          src={ TriangleOrange }
          className="faqs__shape faqs__shape--triangle-orange"
          animate={{
            y: [0, 5, -5, 0],
            rotate: [0, 45, 0]
          }}
          transition={{ repeat: Infinity, duration: 10 }}
        />
      </div>
    </div>
  </div>
);

export default FaqShapes;
