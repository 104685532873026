import React from "react"

import './styles.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="container">
        <p>
          Copyright © { currentYear } <strong>Peera</strong>. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
