import React, { memo } from "react"
import { graphql, StaticQuery } from "gatsby";
import { motion } from "framer-motion";
import Slider from 'react-slick';

import HowWeWorkSection from '../how-we-work-section/index';

import ChevronLeft from '../../assets/images/chevron-purple-left.svg';
import ChevronRight from '../../assets/images/chevron-purple-right.svg';

import './styles.scss';

const NextArrow = ({ onClick }) => (
  <button className="slider-button slider-button--next" onClick={ onClick }>
    <img src={ ChevronRight } alt=""/>
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button className="slider-button slider-button--prev" onClick={ onClick }>
    <img src={ ChevronLeft} alt=""/>
  </button>
);

const HowWeWork = memo(({ data }) => {
  const slides = data.allHowWeWorkJson.nodes;
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 940,
      },
      {
        breakpoint: 660,
        settings: {
          arrows: false,
        }
      }
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: function() {
      return (
        <div className="slider-dot" />
      )
    }
  };
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div className="how-we-work">
      <div className="container">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          className="how-we-work__slider"
        >
          <Slider {...settings}>
            { slides.map((slide, i) => (
              <HowWeWorkSection
                body={ slide.body }
                img={ slide.img }
                img_alt={ slide.img_alt }
                key={ `how-we-work-${i}` }
                title={ slide.title }
              />
            ))}
          </Slider>
        </motion.div>
      </div>
    </div>
  );
});

export default () => (
  <StaticQuery
    query={graphql`
      query HowWeWorkQuery {
        allHowWeWorkJson {
          nodes {
            body
            img
            img_alt
            title
          }
        }
      }
    `}
    render={(data, count) => <HowWeWork data={data} />}
  />
)
