import React from 'react';

import './styles.scss';

const Layout = ({ children }) => (
  <div className="layout">
    { children }
  </div>
);

export default Layout;