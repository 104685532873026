import React, { useState } from "react"
import { Waypoint } from 'react-waypoint';

import Layout from '../components/layout/index';
import Navbar from "../components/navbar";
import Hero from '../components/hero/index';
import HowWeWork from "../components/how-we-work";
import WhatWeDo from "../components/what-we-do";
import WhyChooseUs from "../components/why-choose-us";
import Faqs from "../components/faqs";
import Contact from "../components/contact";
import Footer from "../components/footer";
import SEO from "../components/seo";

import './styles.scss';

const Homepage = () => {
  const [activeSection, setActiveSection] = useState(0);

  return (
    <Layout>
      <SEO title="Welcome" />
      <Navbar activeSection={ activeSection } />
      <Hero id="hero" />
      <Waypoint
        onEnter={ () => setActiveSection(0) }
        topOffset={ 700 }
      />
      <Waypoint
        onEnter={ () => setActiveSection(1) }
        bottomOffset={ 850 }
      />
      <HowWeWork id="how-we-work" />
      <Waypoint
        onEnter={ () => setActiveSection(2) }
        bottomOffset={ 900 }
      />
      <WhatWeDo id="what-we-do" />
      <Waypoint
        onEnter={ () => setActiveSection(3) }
        bottomOffset={ 600 }
      />
      <WhyChooseUs id="why-choose-us" />
      <Waypoint
        onEnter={ () => setActiveSection(4) }
        bottomOffset={ 800 }
      />
      <Faqs id="faqs" />
      <Waypoint
        onEnter={ () => setActiveSection(5) }
        bottomOffset={ 800 }
      />
      <Contact id="contact" />
      <Footer />
    </Layout>
  );
}

export default Homepage;
