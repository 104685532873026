import React from "react"
import { motion, AnimatePresence } from "framer-motion";

import classList from '../../helpers/class_list';

import './styles.scss';

const Accordion = ({ body, heading, i, expanded, setExpanded }) => {
  const open = i === expanded;

  return (
    <div className={ classList("accordion", { open })}>
      <header
        className="accordion__header"
        onClick={() => setExpanded(open ? false : i)}
        onKeyPress={() => setExpanded(open ? false : i)}
        role="button"
        tabIndex={ 0 }
      >
        { heading }
        <div className="accordion__icon" />
      </header>
      <AnimatePresence initial={ false }>
        {open && (
          <motion.section
            className="accordion__body"
            key={ `accordion-${i}` }
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
