import React from "react"
import { motion } from "framer-motion";

import classList from '../../helpers/class_list';

import SquarePurple from '../../assets/images/square-purple.png';
import CircleGreen from '../../assets/images/circle-green.svg';
import CirclePurple from '../../assets/images/circle-purple.svg';

import './styles.scss';

const HowWeWorkSection = ({
  body,
  img,
  img_alt,
  title,
  visible,
}) => (
  <div className={ classList('how-we-work-slide', { visible }) }>
    <div className="how-we-work-slide__section">
      <div className="how-we-work-slide__img">
        <img src={ img } alt={ img_alt }/>
        <div className="how-we-work-slide__shapes how-we-work-slide__shapes--left">
          <motion.img
            className="how-we-work-slide__shape how-we-work-slide__shape--square-purple"
            src={ SquarePurple }
            alt={ img_alt }
            animate={{
              y: [0, 5, 0],
              rotate: [0, 180, 0]
            }}
            transition={{ repeat: Infinity, duration: 10 }}
          />
          <motion.img
            className="how-we-work-slide__shape how-we-work-slide__shape--circle-green"
            src={ CircleGreen }
            alt={ img_alt }
            animate={{
              y: [0, 5, -5, 0],
              x: [0, -5, 5, 0]
            }}
            transition={{ repeat: Infinity, duration: 20 }}
          />
        </div>
        <div className="how-we-work-slide__shapes how-we-work-slide__shapes--right">
          <motion.img
            className="how-we-work-slide__shape how-we-work-slide__shape--circle-purple"
            src={ CirclePurple }
            alt={ img_alt }
            animate={{
              y: [0, -5, 5, 0],
              x: [0, 5, -5, 0]
            }}
            transition={{ repeat: Infinity, duration: 15 }}
          />
          <motion.img
            className="how-we-work-slide__shape how-we-work-slide__shape--circle-green"
            src={ CircleGreen }
            alt={ img_alt }
            animate={{
              y: [0, -5, 5, 0]
            }}
            transition={{ repeat: Infinity, duration: 10 }}
          />
        </div>
      </div>
      <div className="how-we-work-slide__content">
        <h3 className="how-we-work-slide__title">
          { title }
        </h3>
        <div className="how-we-work-slide__body" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </div>
  </div>
);

export default HowWeWorkSection;
