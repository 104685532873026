import React, { memo } from "react"
import { motion } from "framer-motion";
import { Link } from "react-scroll";

import HeroText from './hero-text/index';

import EllipseBlue from '../../assets/images/heroellipseblue.svg';
import EllipsePurple from '../../assets/images/heroellipsepurple.svg';
import CircleOrange from '../../assets/images/circle-orange.svg';
import CircleGreen from '../../assets/images/circle-green.svg';
import CodeLines from '../../assets/images/code-lines.svg';
import SquarePurple from '../../assets/images/square-purple.png';
import TriangleOrange from '../../assets/images/triangle-orange.svg';

import './styles.scss';

const Hero = memo(() => {
  const contentVariants = {
    hidden: { opacity: 0, y: 200 },
    visible: { opacity: 1, y: 0 }
  };

  const shapeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  return (
    <div className="hero">
      <div className="container">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={ contentVariants }
          transition={{ duration: 0.3 }}
          className="hero__content"
        >
          <h4 className="hero__subtitle">
            From building blocks to finished apps
          </h4>
          <HeroText />
          <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            duration={500}
            offset={ -81 }
          >
            <button className="button hero__button">
              Let's chat
            </button>
          </Link>
        </motion.div>
          <motion.img
            src={ EllipsePurple }
            className="hero__shape hero__shape--ellipse-purple"
            alt="bg-ellipse-purple"
            animate={{
              rotate: [0, 45, 0],
              y: [0, -20, 20, 0]
            }}
            transition={{ repeat: Infinity, duration: 15 }}
          />
        <motion.img
          src={ EllipseBlue }
          className="hero__shape hero__shape--ellipse-blue"
          alt="bg-ellipse-blue"
          animate={{
            rotate: [0, 45, 0],
            y: [0, 30, -30, 0]
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        />
        <motion.div
          className="hero__shapes hero__shapes--left"
          initial="hidden"
          animate="visible"
          variants={ shapeVariants }
          transition={{ duration: 0.6 }}
        >
          <motion.img
            src={ CircleOrange }
            className="hero__shape hero__shape--circle-orange"
            animate={{
              y: [0, 5, -5, 0],
              x: [0, -10, 10, 0],
              rotate: [0, 0.05, 0]
            }}
            transition={{ repeat: Infinity, duration: 20 }}
          />
          <motion.img
            src={ SquarePurple }
            className="hero__shape hero__shape--square-purple"
            animate={{
              y: [0, 5, -5, 0],
              rotate: [0, 360, 0]
            }}
            transition={{ repeat: Infinity, duration: 10 }}
          />
          <motion.img
            src={ CircleGreen }
            className="hero__shape hero__shape--circle-green"
            animate={{
              x: [0, 5, -5, 0],
              y: [0, 10, -5, 0],
              rotate: [0, 0.05, 0],
            }}
            transition={{ repeat: Infinity, duration: 12 }}
          />
          <motion.img
            src={ CodeLines }
            className="hero__shape hero__shape--code-lines"
            animate={{
              scale: [1, 1.1, 1],
              rotate: [0, 0.05, 0],
            }}
            transition={{ repeat: Infinity, duration: 10 }}
          />
        </motion.div>
        <div className="hero__shapes hero__shapes--right">
          <motion.img
            src={ CircleOrange }
            className="hero__shape hero__shape--circle-orange"
            animate={{
              x: [0, 5, -5, 0],
              rotate: [0, 0.05, 0],
            }}
            transition={{ repeat: Infinity, duration: 15 }}
          />
          <motion.img
            src={ SquarePurple }
            className="hero__shape hero__shape--square-purple"
            animate={{
              y: [0, -5, 5, 0],
              rotate: [0, 180, 0]
            }}
            transition={{ repeat: Infinity, duration: 15 }}
          />
          <motion.img
            src={ CircleGreen }
            className="hero__shape hero__shape--circle-green"
            animate={{
              x: [0, 5, -5, 0],
              rotate: [0, 0.05, 0],
            }}
            transition={{ repeat: Infinity, duration: 10 }}
          />
          <motion.img
            src={ TriangleOrange }
            className="hero__shape hero__shape--triangle-orange"
            animate={{
              y: [0, 5, -5, 0],
              rotate: [0, 45, 0]
            }}
            transition={{ repeat: Infinity, duration: 10 }}
          />
        </div>
      </div>
    </div>
  );
});

export default Hero;
